.introSteps {
    list-style-type:none;
    counter-reset: introStep;
    padding:0 20px;
    margin:20px 0;
    box-shadow:0 1px 4px 1px rgba(0,0,0,.25);

    li {
        display:flex;
        position:relative;
        padding-left:100px;
        padding:15px 0;
        border-bottom:1px solid $sf-color-halflightgray;
        opacity:.5;

        &:last-child {
            border-bottom:none;
        }

        &::before {
            counter-increment: introStep;
            content: counter(introStep);
            position:absolute;
            left:0;
            top:50%;
            transform:translateY(-50%);
            width:100px;
            text-align:center;
            font-size:96px;
            line-height:1;
            color:$sf-color-halflightgray;
        }

        .stepDescription {
            flex-grow:1;
            padding-left:120px;
            min-height:80px;
            padding-right:20px;
        }

        .stepIndicator {
            width:100px;
            position:relative;
            flex-shrink:0;

            &::before {
                position:absolute;
                right:0;
                width:100%;
                text-align:center;
                font-size:64px;
                transform:translateY(-50%);
                top:50%;
                font-family: "Font Awesome 5 free";
                color:$sf-color-halflightgray;
                content:"\f111";
            }


        }
        .stepButton, .skipButton {
            margin-top:20px;
        }

        &.done {
            .stepIndicator::before {
               color:$success;
               content:"\f058";
            }
        }

        &.active {
            .stepIndicator::before {
                color:$body-color;
             } 
        }

        &.waiting {
            .stepIndicator::before {
               color:$sf-color-gray;
               content:"\f017";
            }
        }

        &.done, &.active, &.waiting {
            opacity:1;

            &::before {
                color:$sf-color-primary;
            }
        }
    }
}

/* INTRO JS OVERRIDES */
.introjs-tooltip {
    background:rgba(0,0,0,.75);

    &::before {
        content:"";
        background:url('/img/dashboard_fox.png');
        background-size:cover;
        position:absolute;
        width:150px;
        height:151px;
    }
    &.introjs-top-left-aligned::before{
        left: -175px;
        top: 35px;
    }
    &.introjs-left::before {
        right:0;
        bottom:-160px;
    }
    &.introjs-bottom-left-aligned::before {
        top:-160px;
        left:-160px;
    }
    &.introjs-right::before {
        transform:scaleX(-1);
        left:0;
        bottom:-160px;
    }
}