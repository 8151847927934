@font-face {
    font-family: 'schadenfux';
    src: url('../fonts/schadenfux.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

.sf-icon {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'schadenfux' !important;
    /*speak: none;*/
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.sf-reparaturarbeiten:before {
    content: "\e920";
  }
.sf-termine:before {
    content: "\e91f";
}
.sf-einstellungen:before {
    content: "\e91e";
}
.sf-elementar:before {
    content: "\e90a";
}
.sf-feuerbrandblitz:before {
    content: "\e90b";
}
.sf-glas:before {
    content: "\e90c";
}
.sf-haustechnik:before {
    content: "\e90d";
}
.sf-leitungswasser:before {
    content: "\e90e";
}
.sf-sturmhagel:before {
    content: "\e90f";
}
.sf-vandalismus:before {
    content: "\e910";
}
.sf-ansprechpartner:before {
    content: "\e911";
}
.sf-handwerker:before {
    content: "\e912";
}
.sf-hausmeister:before {
    content: "\e913";
}
.sf-makler:before {
    content: "\e914";
}
.sf-kontakte:before {
    content: "\e915";
}
.sf-leckortung:before {
    content: "\e916";
}
.sf-schadenbehebung:before {
    content: "\e917";
}
.sf-trocknung:before {
    content: "\e918";
}
.sf-wiederherstellung:before {
    content: "\e919";
}
.sf-angebot:before {
    content: "\e91a";
}
.sf-bild:before {
    content: "\e91b";
}
.sf-rechnung:before {
    content: "\e91c";
}
.sf-ordner:before {
    content: "\e91d";
}
.sf-aufgaben:before {
    content: "\e900";
}
.sf-home:before {
    content: "\e901";
}
.sf-kundenverwaltung:before {
    content: "\e902";
}
.sf-mitteilungen:before {
    content: "\e903";
}
.sf-objektverwaltung:before {
    content: "\e904";
}
.sf-reports:before {
    content: "\e905";
}
.sf-bearbeiten:before {
    content: "\e906";
}
.sf-liste:before {
    content: "\e907";
}
.sf-telefonbuch:before {
    content: "\e908";
}
.sf-vorgaenge:before {
    content: "\e909";
}
